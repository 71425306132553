import { useEffect, useState } from "react";
import { getProduct, setProduct } from "./helpers/storage";
import { getBasket } from "./Cart";

const Order1 = () => {
  const [basket, setBasket] = useState(null);
  const [ean, setEan] = useState(null);

  useEffect(() => {
    getBasket().then(({ basket }) => {
      setBasket(basket);
    });
  }, []);

  useEffect(() => {
    setEan(getProduct());
  }, []);
  useEffect(() => {
    if (ean && basket) {
      setProduct(ean);
    }
  }, [ean, basket]);
  return null;
};

export default Order1;
