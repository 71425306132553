import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { MainContextProvider } from "./store/main-context";
const FILE_NAMES = ["signin", "client-new", "pickup-sites"];
const IGNORE = FILE_NAMES.map(filename => [
  `/${filename}.php`,
  `/${filename}.htm`,
]).flat();
const shouldInit = !IGNORE.some((subpath) =>
  window.parent.location.pathname.includes(subpath)
);

if (shouldInit) {
    const root = document.createElement("div");
    root.setAttribute("id", "fffrree-root");
    const doc = window.parent.document;
    document.addEventListener('DOMContentLoaded', () => {
        doc.body.appendChild(root);
        ReactDOM.render(<MainContextProvider><App /></MainContextProvider>, root);
    })
}
