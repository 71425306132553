import Slider from "react-slick";
import Frame from "react-frame-component";
import style from "./style.module.scss";
import { PrevArrow, NextArrow } from "../Arrows";
import { useRef, useState, useContext } from "react";
import Modal from "../Modal";
import { ReactComponent as Top } from "./top.svg";
import { ReactComponent as Logo } from "../logo.svg";
import { MainContext } from "../store/main-context";

const Widget = ({ onClose }) => {
  const { data } = useContext(MainContext);
  const [open, setOpen] = useState(false);
  const iframeRef = useRef();

  if (open) {
    return <Modal data={data} onClose={() => setOpen(false)} />;
  }

  const allProducts = data.price_ranges
    .map((section) => section.products)
    .flat();
  return (
    <Frame
      style={{
        border: 0,
        position: "fixed",
        bottom: 50,
        [data.widget_position === 0 ? "left" : "right"]: 50,
        width: 210,
        height: 420,
        zIndex: 1000,
      }}
      ref={iframeRef}
      contentDidMount={() => {
        window.parent.fffrreeStyles.forEach((el) => {
          iframeRef.current.node.contentDocument.head.append(el);
        });
      }}
    >
      <style>
        {`:root {
          --widgetColor: ${data.widget_color};
        }`}
      </style>
      <div className={style.wrapper}>
        <div className={style.content}>
          <div className={style.bg} />
          <Slider
            autoplay
            slidesToShow={1}
            prevArrow={<PrevArrow extraClassName={style.arrow} />}
            nextArrow={<NextArrow extraClassName={style.arrow} />}
          >
            {allProducts.map((product, i) => (
              <div key={product.product_id} className={style.productWrapper}>
                <div className={style.product}>
                  <img src={`${product.image_url}`} alt="" />
                </div>
              </div>
            ))}
          </Slider>
          <h3 className={style.header}>
            {data?.translations.translation_text_1
              .split(" ")
              .slice(0, 3)
              .join(" ")}
            &nbsp;
            {data?.translations.translation_text_1
              .split(" ")
              .slice(3, -1)
              .join(" ")}{" "}
            <strong>
              {data?.translations.translation_text_1.split(" ").slice(-1)}
            </strong>
          </h3>
          <button className={style.primary} onClick={() => setOpen(true)}>
            {data?.translations.translation_text_7}
          </button>
          {!data.widget_hide_fffrree_logo && (
            <footer className={style.footer}>
              POWERED BY
              <a href="https://fffrree.com/" target="_blank" rel="noreferrer">
                <Logo />
              </a>
            </footer>
          )}
        </div>
        <Top className={style.top} />
        <button className={style.close} onClick={onClose}>
          &times;
        </button>
      </div>
    </Frame>
  );
};

export default Widget;
