import React, { createContext, useState } from 'react';

const MainContext = createContext();

const MainContextProvider = ({ children }) => {
  const [data, setData] = useState(null);

  return (
    <MainContext.Provider value={{setData, data}}>
      {children}
    </MainContext.Provider>
  );
};

export { MainContext, MainContextProvider };
