import styles from "../style.module.scss";
import { ReactComponent as Logo } from "../logo.svg";
import { Section } from "../Section";
import { useEffect, useState, useContext } from "react";
import { getProduct, setProduct, clear } from "../helpers/storage";
import CustomFrame from "../components/Frame";
import { watchCart, getUrl } from "./helpers";
import { findProduct, formatPrice, getProducts } from "../helpers";
import getWidgetKey from "../helpers/getWidgetKey";
import query, { queryAll, queryByTagAndText } from "../helpers/query";
import { MainContext } from "../store/main-context";

const isDev = () => {
  return window.location.origin.includes("localhost");
};

export const getBasket = async () => {
  const url = getUrl("/ajax/basket.php");
  if (isDev()) {
    return await {
      basket: {
        worth: 101,
      },
    };
  }
  return await fetch(url).then((response) => response.json());
};

const Cart = () => {
  const { data } = useContext(MainContext);
  const { sections, tempProductId } = data ?? {};
  const [open, setOpen] = useState(true);
  const [basket, setBasket] = useState(null);
  const [ean, setEan] = useState(getProduct());
  const [isFetching, setIsFetching] = useState(false);
  const [disabledProducts, setDisabledProducts] = useState([]);

  useEffect(() => {
    if (basket) {
      return;
    }
    getBasket().then(({ basket }) => {
      setEan(getProduct());
      setBasket(basket);
    });
    watchCart();
  }, [sections, basket]);

  useEffect(() => {
    if (ean && basket) {
      setProduct(ean);
    }
    if (ean === "") {
      clear();
    }
  }, [ean, basket]);

  if (ean) {
    const products = getProducts(sections);
    const product = findProduct(products, ean);
    const nameNode = queryByTagAndText("a", "produkt gratisowy");
    const row = `[data-product-id="${tempProductId}"]`;
    // as alternative move it to getbasket
    if (!query(row)) {
      clear();
      window.location.reload();
    }
    if (nameNode) {
      nameNode.innerText = product.name;
    }
    queryAll('[alt="Produkt gratisowy"]')?.forEach((el) => {
      el.setAttribute("src", product.image_url);
    });
    query(`${row} [href="#remove"]`)?.addEventListener("click", () => {
      clear();
    });
    queryAll(`${row} .product__link, ${row} .photo__link`).forEach((node) => {
      node.setAttribute("href", product.url);
    });
  }

  if (ean || !open) {
    return null;
  }
  const isLoading = !(basket && sections);
  const basketWorth = basket?.worth || 0;
  const basketWorthFormatted = formatPrice(
    basketWorth,
    data?.gratis_price_suffix
  );

  return (
    <CustomFrame type="full">
      <style>
        {`:root {
          --widgetColor: ${data.widget_color};
        }`}
      </style>
      <div className={styles.backdrop} />
      <div className={styles.wrapper}>
        <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
          <button className={styles.close} onClick={() => setOpen(false)}>
            &times;
          </button>
          <h2>Twój koszyk</h2>
          <div className={styles.cartAmount}>
            {data?.translations.translation_text_2}
            <strong>{basketWorthFormatted}</strong>
          </div>

          {!data.widget_hide_fffrree_logo && (
            <a href="https://fffrree.com/" target="_blank" rel="noreferrer">
              <Logo className={styles.logo} alt="logo" />
            </a>
          )}

          <header>
            <h1>
              {data?.translations.translation_text_3.split(" ")[0]}{" "}
              <strong>
                {data?.translations.translation_text_3.split(" ")[1]}
              </strong>
            </h1>
            <p>
              {data?.translations.translation_text_4
                .split(" ")
                .slice(0, 2)
                .join(" ")}{" "}
              <strong>
                {data?.translations.translation_text_4
                  .split(" ")
                  .slice(2, 4)
                  .join(" ")}
              </strong>{" "}
              {data?.translations.translation_text_4
                .split(" ")
                .slice(4)
                .join(" ")}
            </p>
          </header>
          <div className={styles.clearfix} />
          {isLoading && <div className={styles.loading}>Loading</div>}
          {!isLoading &&
            sections.map((section, i) => {
              let header = `${data?.translations.translation_text_5} ${section.from} ${data?.gratis_price_suffix}`;
              const disabled = basketWorth < section.from;
              if (disabled) {
                const left = section.from - (basket?.worth | 0);
                header = `${header} - brakuje ${formatPrice(
                  left,
                  data?.gratis_price_suffix
                )}`;
              }
              return (
                <Section
                  key={i}
                  header={header}
                  section={section}
                  disabled={disabled || isFetching}
                  disabledProducts={disabledProducts}
                  onClick={(product) => {
                    if (!disabled) {
                      setIsFetching(true);

                      fetch(getUrl("/graphql/v1/"), {
                        method: "POST",
                        body: JSON.stringify({
                          query: `mutation { addProductsToBasket( ProductInput: {id: ${tempProductId}, size: "uniw", quantity: 1} ){status}}`,
                        }),
                      }).then(() => {
                        setIsFetching(false);
                        setEan(product.product_id);
                        setTimeout(() => {
                          window.parent.location.reload();
                        }, 100);
                      });
                    }
                  }}
                />
              );
            })}

          <div className={styles.cartAmount}>
            {data?.translations.translation_text_2}
            <strong>{basketWorthFormatted}</strong>
          </div>

          <button className={styles.btn} onClick={() => setOpen(false)}>
            {data?.translations.translation_text_6}
          </button>
        </div>
      </div>
    </CustomFrame>
  );
};

export default Cart;
