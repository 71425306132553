import { useEffect } from "react";
import getParam from "./helpers/getParam";
import getWidgetKey from "./helpers/getWidgetKey";
import { queryAll, queryByTagAndText } from "./helpers/query";
import { clear, getProduct } from "./helpers/storage";
import { findProduct, getProducts } from "./helpers";

const widgetKey = getWidgetKey();

const URL = `${process.env.REACT_APP_API}proxy/api/order/`;

const post = async (url, data) => {
    const response = await fetch(url, {
        method: "POST", 
        headers: {
            accept: "*/*",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    });
    if (response.status >= 400) {
        throw response.status;
    }
    return response.json();
};

const updateOrder = (orderId, productId) => post(URL, {
    store_integration_api_key: widgetKey?.toString() ?? undefined,
    store_order_id: orderId?.toString() ?? undefined,
    product_id: productId?.toString() ?? undefined,
});

let requestSend = false;

const Order = ({ sections, tempProductId }) => {
  const productId = getProduct();
  const orderId = +getParam("order_number");
  window.parent.document.body.style.display = "block";
  useEffect(() => {
    if (requestSend) {
      return false;
    }
    
    if (orderId && sections) {
      requestSend = true;
      updateOrder(orderId, productId)
        .then(() => {
          setTimeout(() => {
            if (productId) {
                const products = getProducts(sections);
                const freeProduct = findProduct(products, productId);
                const nameNode = queryByTagAndText('a', 'produkt gratisowy');
                if(nameNode) {
                    nameNode.innerText = freeProduct.name;
                }
                queryAll('[alt="Produkt gratisowy"]')?.forEach(el => {
                    el.setAttribute('src', freeProduct.image_url);
                });
                queryAll(`[href="/pl/products/produkt-gratisowy-${tempProductId}.html`)?.forEach(el => {
                    el.setAttribute('href', freeProduct.url);
                });
                clear();
            }
          });
        })
        .catch((status) => {
          if (status === 409) {
            return false;
          }
          clear();
          if(status === 423) {
            return false;
          }
        });
    }
  }, [sections, productId, orderId, tempProductId]);

  return null;
};

export default Order;
