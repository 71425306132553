import styles from "./style.module.scss";
import { ReactComponent as Logo } from "./logo.svg";
import CustomFrame from "./components/Frame";

const Modal = ({ data: { widget_color, widget_hide_fffrree_logo }, onClose }) => {
  return (
    <>
      <style>
        {`#dialog_cover,#dialog_wrapper {
        display: none;
      }`}
      </style>
      <CustomFrame type="full">
        <style>
          {`:root {
          --widgetColor: ${widget_color};
        }`}
        </style>
        <div className={styles.backdrop} />
        <div className={styles.wrapper}>
          <div
            className={styles.modal}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <button className={styles.close} onClick={onClose}>
              &times;
            </button>

            {!widget_hide_fffrree_logo && (
              <a href="https://fffrree.com/" target="_blank" rel="noreferrer">
                <Logo className={styles.logo} alt="logo" />
              </a>
            )}

            <header>
              <h1 style={{ marginTop: 14 }}>
                <strong>Błąd!</strong>
              </h1>
            </header>
            <div className={styles.clearfix} />
            Nie możesz dodać tego produktu do koszyka.
            <button className={styles.btn} onClick={onClose}>
              zamknij
            </button>
            <div className={styles.clearfix} />
          </div>
        </div>
      </CustomFrame>
    </>
  );
};

export default Modal;
