import { useEffect, useState } from "react";
import { getBasket } from "./Cart";
import { findProduct, getProducts } from "./helpers";
import query, { queryAll } from "./helpers/query";
import { getProduct } from "./helpers/storage";

const Order2 = ({ sections }) => {
  const [basket, setBasket] = useState(null);
  const [ean, setEan] = useState(null);

  useEffect(() => {
    setEan(getProduct());
    getBasket().then(({ basket }) => {
      setBasket(basket);
    });
  }, []);

  useEffect(() => {
    if (!ean || !basket) {
      return false;
    }
    const products = getProducts(sections);
    const product = findProduct(products, ean);
    if (query('[title="Produkt gratisowy"]')){
      query('[title="Produkt gratisowy"]').innerText = product.name;
    }
    if(query('[href*="Produkt-gratisowy.html"]:not(:has(img))')){
      query('[href*="Produkt-gratisowy.html"]:not(:has(img))').innerText = product.name;
    }
    queryAll('[alt="Produkt gratisowy"]').forEach(el => {
      el.setAttribute('src', product.image_url);
    });
  }, [sections, ean, basket]);

  return null;
};

export default Order2;
