import query, { queryAll } from "../helpers/query";
import { getProduct, clear } from "../helpers/storage";

const addSign = () => {
  const table = query("table");
  const info = document.createElement("div");
  info.innerHTML = "Przelicz zawartość w koszyku aby odblokować nowe promocje";
  table.parentNode.insertBefore(info, table);
};

export const watchCart = () => {
  queryAll("[data-unit]").forEach((el) => {
    el.addEventListener("change", addSign);
  });
  queryAll(".addQuantity, .delQuantity").forEach((el) => {
    el.addEventListener("click", addSign);
  });
};

export const validate = ({ products = [], worth }, sections, freeProductId) => {
  const ean = getProduct();
  if (products.find(p => p.id === freeProductId.toString())) {
    if (!ean) {
      return false;
    }
  }
  if (!ean) {
    return undefined;
  }
  let result = true;
  sections.forEach((section) => {
    section.products.forEach((product) => {
      if (product.product_id === +ean) {
        if (worth < section.from) {
          clear();
          result = false;
        }
      }
    });
  });
  return result;
};

export const getUrl = (path) => {
  const { origin } = window.location;
  return `${origin}${path}`;
};

export const removeProductFromBasket = ({ id, md5_additional }) => fetch(getUrl('/graphql/v1/'), {
  method: 'POST',
  body: JSON.stringify({
    query: `mutation { removeProductsFromBasket( ProductInput: {id: ${id}, size: "uniw", additional: "${md5_additional}"} ){status}}`,
  })
})