import { useRef, useState } from "react";
import Frame from "react-frame-component";

const STYLES = {
  widget: {
    border: 0,
    position: "fixed",
    bottom: 50,
    right: 50,
    width: 210,
    height: 420,
    zIndex: 1000,
  },
  full: {
    border: 0,
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    zIndex: 1000,
  },
};

const CustomFrame = ({ type, children }) => {
  const iframeRef = useRef();
  const [loading, setLoading] = useState(true);
  return (
    <Frame
      style={STYLES[type]}
      ref={iframeRef}
      contentDidMount={() => {
        window.parent.fffrreeStyles.forEach((el) => {
          iframeRef.current.node.contentDocument.head.append(el);
        });
        setLoading(false);
      }}
    >
      <div style={{ opcaity: loading ? 0 : 1 }}>{children}</div>
    </Frame>
  );
};

export default CustomFrame;
