import styles from "./style.module.scss";
import { ReactComponent as Logo } from "./logo.svg";
import { Section } from "./Section";
import CustomFrame from "./components/Frame";
import { useContext } from "react";
import { MainContext } from "./store/main-context";

const Modal = ({
  data: { price_ranges, widget_color, widget_hide_fffrree_logo },
  onClose,
}) => {
  const { data } = useContext(MainContext);

  const sectionsWithHeaders = price_ranges.map((section) => ({
    ...section,
    header: `${data?.translations.translation_text_5} ${
      section.from ?? "-"
    } ${data?.gratis_price_suffix}`,
  }));
  return (
    <CustomFrame type="full">
      <style>
        {`:root {
          --widgetColor: ${widget_color};
        }`}
      </style>
      <div className={styles.backdrop} />
      <div className={styles.wrapper}>
        <div
          className={styles.modal}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <button className={styles.close} onClick={onClose}>
            &times;
          </button>

          {!widget_hide_fffrree_logo && (
            <a href="https://fffrree.com/" target="_blank" rel="noreferrer">
              <Logo className={styles.logo} alt="logo" />
            </a>
          )}

          <header>
            <h1 style={{ marginTop: 14 }}>
              <strong>
                {data?.translations.translation_text_8.split(" ")[0]}
              </strong>{" "}
              {data?.translations.translation_text_8
                .split(" ")
                .slice(1)
                .join(" ")}
            </h1>
          </header>
          <div className={styles.clearfix} />
          {sectionsWithHeaders.map((section, i) => (
            <Section key={i} header={section.header} section={section} />
          ))}
          <button className={styles.btn} onClick={onClose}>
            {data?.translations.translation_text_6}
          </button>
          <div className={styles.clearfix} />
        </div>
      </div>
    </CustomFrame>
  );
};

export default Modal;
