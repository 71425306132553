import Slider from "react-slick";
import styles from "./style.module.scss";
import { useContext } from "react";
import { PrevArrow, NextArrow } from "./Arrows";
import { MainContext } from "./store/main-context";

export const Section = ({
  disabled = false,
  header = '',
  section,
  disabledProducts = [],
  onClick,
}) => {
  const { data } = useContext(MainContext);

  if (section.products.length === 0) {
    return null;
  }
  const products = [
    ...section.products,
    ...section.products,
    ...section.products,
    ...section.products,
    ...section.products,
  ];
  return (
    <div className={`${styles.card} ${disabled ? styles.disabled : ""}`}>
      <h3>{header}</h3>
      <div className={styles.body}>
        <Slider
          slidesToShow={5}
          prevArrow={<PrevArrow />}
          nextArrow={<NextArrow />}
          responsive={[
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
              },
            },
            {
              breakpoint: 576,
              settings: {
                slidesToShow: 1,
              },
            },
          ]}
        >
          {products.map((product) => (
            <div
              key={product.product_id}
              className={`${styles.product} ${
                onClick ? styles.clickable : ""
              } ${
                disabledProducts.includes(product.product_id)
                  ? styles.disabledProduct
                  : ""
              }`}
              onClick={() => onClick(product)}
            >
              <div className={styles.content}>
                <div className={styles.imageWrapper}>
                  <img src={`${product.image_url}`} alt="" />
                  <span>Dodaj</span>
                </div>
                <div
                  className={styles.tag}
                  dangerouslySetInnerHTML={{
                    __html: data?.gratis_price_html,
                  }}
                />
                <h4>
                  {!!product.url && (
                    <a
                      href={product.url}
                      target="_blank"
                      rel="noreferrer"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {product.name}
                    </a>
                  )}
                  {!product.url && product.name}
                </h4>
              </div>
            </div>
          ))}
        </Slider>
        <div className={styles.clearfix} />
      </div>
    </div>
  );
};
