import { useEffect, useRef, useState, useContext } from "react";
import Frame from "react-frame-component";
import style from "./style.module.scss";
import Open from "./Open";
import { isMobile } from "../helpers";
import { getOpen, setOpen as setOpenStorage } from "../helpers/storage";
import { MainContext } from "../store/main-context";

const openStorage = getOpen();

const Widget = () => {
  const { data } = useContext(MainContext);
  const [open, setOpen] = useState(
    openStorage ? openStorage === "true" : !isMobile()
  );
  const iframeRef = useRef();
  useEffect(() => {
    setOpenStorage(open);
  }, [open]);
  if (open) {
    return <Open onClose={() => setOpen(false)} />;
  }
  return (
    <Frame
      style={{
        border: 0,
        position: "fixed",
        bottom: 50,
        [data.widget_position === 0 ? "left" : "right"]: 50,
        width: 69,
        height: 71,
        zIndex: 1000,
      }}
      scrolling="no"
      ref={iframeRef}
      contentDidMount={() => {
        window.parent.fffrreeStyles.forEach((el) => {
          iframeRef.current.node.contentDocument.head.append(el);
        });
      }}
    >
      <style>
        {`:root {
          --widgetColor: ${data.widget_color};
        }`}
      </style>
      <button className={style.icon} onClick={() => setOpen(true)}>
        ODBIERZ GRATIS
      </button>
    </Frame>
  );
};

export default Widget;
