import styles from "./style.module.scss";

export const PrevArrow = (props) => {
  const { className, currentSlide, slideCount, extraClassName, ...rest } =
    props;
  return (
    <button className={`${styles.arrow} ${extraClassName}`} {...rest}>
      &lt;
    </button>
  );
};
export const NextArrow = (props) => {
  const { className, currentSlide, slideCount, extraClassName, ...rest } =
    props;
  return (
    <button
      className={`${styles.arrow} ${styles.arrowRight} ${extraClassName}`}
      {...rest}
    >
      &gt;
    </button>
  );
};
