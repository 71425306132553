const query = (selector) => window.parent.document.querySelector(selector);

export const queryAll = (selector) => [
  ...window.parent.document.querySelectorAll(selector),
];

export const queryByTagAndText = (selector, query, exact) =>
  queryAll(selector).find(
    (node) => {
      const text = node.textContent?.trim().toLowerCase();
      if (exact){
        return text === query;
      }
      return text.includes(query);
    }
  );

export default query;
