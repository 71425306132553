import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Widget from "./Widget";
import Cart, { getBasket } from "./Cart";
import { useContext, useEffect, useState } from "react";
import Order from "./Order";
import Order1 from "./Order1";
import getWidgetKey from "./helpers/getWidgetKey";
import { clear, getProduct } from "./helpers/storage";
import Order2 from "./Order2";
import { queryAll } from "./helpers/query";
import { DEBUG } from "./constants";
import { findProduct, getProducts } from "./helpers";
import { removeProductFromBasket, validate } from "./Cart/helpers";
import WarningModal from "./WarningModal";
import { MainContext } from "./store/main-context";

const widgetKey = getWidgetKey();

const currentLang =
  window.app_shop?.vars?.language?.id ??
  ((window.app_shop?.vars?.curr_url ?? "").includes("-eng") ? "eng" : null) ??
  null;

const currentCurrency =
  window.app_shop?.vars?.currency?.id ??
  window.app_shop?.vars?.id ??
  (window.app_shop?.vars?.symbol
    ? { zł: "PLN", "€": "EUR" }[window.app_shop.vars.symbol.toLowerCase()]
    : null) ??
  null;

const URL = `${
  process.env.REACT_APP_API
}proxy/api/data/?store_integration_api_key=${widgetKey}${
  currentLang ? `&country_code=${currentLang}` : ""
}${currentCurrency ? `&currency_code=${currentCurrency}` : ""}`;

const App = () => {
  const { data, setData } = useContext(MainContext);
  const [warning, setWarning] = useState(false);
  const [widgetCanFetch, setWidgetCanFetch] = useState(false);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  // Initial

  useEffect(() => {
    fetch(URL)
      .then((response) => response.json())
      .then((response) => {
        setDateFrom(response.date_from);
        setDateTo(response.date_to);
        setWidgetCanFetch(true);
      });
  }, []);

  useEffect(() => {
    if (!widgetCanFetch) return;

    if (dateFrom) {
      if (new Date(data.date_from) > new Date()) {
        return;
      }
    }

    if (dateTo) {
      if (new Date(data.date_to) < new Date()) {
        return;
      }
    }

    fetch(URL)
      .then((response) => response.json())
      .then((response) => {
        const sections = response.price_ranges;
        if (!sections || sections.length === 0) {
          window.parent.document.body.style.display = "block";
          return;
        }
        if (response.is_widget_enabled === false) {
          window.parent.document.body.style.display = "block";
          return;
        }

        getBasket().then(({ basket }) => {
          const isValid = validate(
            basket,
            response.price_ranges,
            response.free_product_id
          );
          if (isValid === false) {
            const tempProduct = basket.products.find(
              (p) => p.id === response.free_product_id.toString()
            );
            if (tempProduct) {
              removeProductFromBasket(tempProduct);
              if (!pathname.includes("basket")) {
                setWarning(true);
              } else {
                window.location.reload();
              }
            }
          }
        });

        const nativeFetch = fetch;
        window.fetch = (...args) => {
          if (args[0].includes(`product=${response.free_product_id}`)) {
            throw new Error();
          }
          return nativeFetch(...args);
        };

        setData({
          //widget_hide_fffrree_logo
          ...response,
          sections,
          // deprecated, use price_ranges and free_product_id instead
          tempProductId: response.free_product_id,
          translations: response.translations,
          gratis_price_html:
            `${
              response.free_product_price /
              (response.currency?.toLowerCase() === "pln" ? 1.23 : 1)
            } ${response.suffix} ` +
            (response.currency?.toLowerCase() === "pln"
              ? `<small>+23% VAT</small>`
              : ""),
          gratis_price_value: response.free_product_price,
          gratis_price_suffix: response.suffix,
        });
      });
  }, [widgetCanFetch, setData]);

  const { pathname, search } = window.parent.location;
  if (pathname.includes("orderdetails")) {
    if (DEBUG === false) {
      window.parent.document.body.style.display = "none";
    }

    return (
      <Order sections={data?.sections} tempProductId={data?.tempProductId} />
    );
  }
  if (data) {
    const basketRows = queryAll(".topBasket__row");
    if (basketRows && basketRows.length > 0) {
      const freeProduct = basketRows.find((row) =>
        row.querySelector("a").getAttribute("href").includes(data.tempProductId)
      );
      if (freeProduct) {
        const product = getProduct();
        if (product) {
          const products = getProducts(data?.sections);
          const productData = findProduct(products, product);
          const links = freeProduct.querySelectorAll("a");
          links?.forEach((link) => link.setAttribute("href", productData.url));
          const name = freeProduct.querySelector(".topBasket__name");
          if (name) name.innerText = productData.name;
          const image = freeProduct.querySelector("picture,.--photo");
          if (image) {
            image
              .querySelector("img")
              .setAttribute("src", productData.image_url);
            image
              .querySelector("source")
              ?.setAttribute("srcset", productData.image_url);
          }
        }
      }
    }

    if (warning) {
      return (
        <WarningModal
          data={data}
          onClose={() => (window.parent.location.href = "/")}
        />
      );
    }

    if (pathname.includes("basket")) {
      return <Cart />;
    }
    if (pathname.includes("order1")) {
      return <Order1 sections={data.sections} />;
    }
    if (pathname.includes("order2")) {
      return <Order2 sections={data.sections} />;
    }
    if (pathname.includes("return") && search.includes("basket_empty")) {
      clear();
      return null;
    }
    return <Widget />;
  }
  return null;
};

export default App;
