const PREFIX = "fffrreecom-";
const KEY_PRODUCT = `${PREFIX}product`;
const KEY_OPEN = `${PREFIX}open`;

export const setProduct = (ean) => localStorage.setItem(KEY_PRODUCT, ean);
export const getProduct = () => localStorage.getItem(KEY_PRODUCT);
export const clear = () => localStorage.removeItem(KEY_PRODUCT);

export const setOpen = (open) => localStorage.setItem(KEY_OPEN, open);
export const getOpen = () => localStorage.getItem(KEY_OPEN);
