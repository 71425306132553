import query from "./query";

const TEST_KEY =
  "1405d474189be10a2959f491fb4bd1b24e411db057552c10d43188ef988d0b05";

const getWidgetKey = () =>
  query('script[src*="app.fffrree.com"]')
    ?.getAttribute("src")
    .split("/")
    .pop()
    .replace(".js", "") || TEST_KEY;

export default getWidgetKey;
